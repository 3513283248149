








































import { defineComponent, PropType, toRefs } from '@nuxtjs/composition-api';
import { SfProductCard } from '@storefront-ui/vue';
import { useProductsWithCommonProductCardProps } from './useProductsWithCommonCardProps';
import { useImage } from '~/composables';
import type { Product } from '~/modules/catalog/product/types';

import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import CategoryProductPrice from '~/modules/catalog/category/components/views/CategoryProductPrice.vue';

export default defineComponent({
  components: {
    CategoryProductPrice,
    SfProductCard,
    SkeletonLoader,
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    pricesLoaded: Boolean,
    loading: Boolean,
  },
  emits: ['click:wishlist', 'click:add-to-cart'],
  setup(props) {
    const { imageSizes: { productCard: imageSize } } = useImage();
    const { products } = toRefs(props);
    const { productsWithCommonProductCardProps } = useProductsWithCommonProductCardProps(products);

    return {
      imageSize,
      productsWithCommonProductCardProps,
    };
  },
});
